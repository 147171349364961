export const classes = [
  { Dubai: 'compact-wrapper' },
  // { London: 'only-body' },
  // { Seoul: 'compact-wrapper modern-type' },
  // { LosAngeles: 'horizontal-wrapper material-type' },
  // { Paris: 'compact-wrapper dark-sidebar' },
  // { Tokyo: 'compact-sidebar' },
  // { Madrid: 'compact-wrapper color-sidebar' },
  // { Moscow: 'compact-sidebar compact-small' },
  // { NewYork: 'compact-wrapper box-layout' },
  // { Singapore: 'horizontal-wrapper enterprice-type' },
  // { Rome: 'compact-sidebar compact-small material-icon' },
  // { Barcelona: 'horizontal-wrapper enterprice-type advance-layout' }
];