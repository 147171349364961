/**
 * Seo component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

const Seo = ({  lang, meta, title, schema }) => {
  meta = [];

  const defaultTitle = "Utrip";

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={(defaultTitle) ? `%s - ${defaultTitle}` : null}
      meta={[].concat(meta)}
    >
      {(!!schema && !!schema['@type']) && <script type="application/ld+json">{JSON.stringify(schema)}</script>}
    </Helmet>
  );
};

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default Seo;
