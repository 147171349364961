import React, { Fragment } from "react";

import $ from "jquery";
import "jquery-ui-dist/jquery-ui";

const SelectMenu = ({ id, className, name, handleOnChange, options, selected, ref, selectValue }) => {

    const selectRef = React.useRef(null);

    const [show, setShow] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        $(selectRef.current).selectmenu({
            position: {
                my: "left top",
                at: "left bottom",
                collision: "flip",
            },
            open: function (event, ui) {
                setOpen(true);
            },
            close: function (event, ui) {
                setOpen(false);
            },
            change: function (event, ui) {
                handleOnChange(event.target.value);
            },
            create: function (event, ui) {
                setShow(true);
            }
        }).selectmenu("refresh")
            .selectmenu("menuWidget")
            .addClass("overflow");
    }, [options, selected]);

    return (
        <>
            {(options?.length && selected != null) > 0 &&
                <div className={`ui-front ${open ? 'active' : ''}`}>
                    <select name={name} className={className} value={selected} id={id} ref={selectRef} onChange={() => { }}>
                        <option value="">{selectValue ? selectValue : 'Select'}</option>
                        <>
                            {
                                options.map((item, key) => {
                                    return <option value={item.value} key={key}>{item.name}</option>
                                })
                            }
                        </>
                    </select>
                </div>
            }
        </>
    );
}

export default SelectMenu;
