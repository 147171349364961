const api = `${process.env.REACT_APP_PUBLIC_URL}/api`;
export const ChartistApi = `${api}/chartistData.json`;
export const ChartjsApi = `${api}/chartjsData.json`;
export const GooglechartApi = `${api}/googleChartData.json`;
export const AllProjectApi = `${api}/allProject.json`;

const backendApi = `${process.env.REACT_APP_BACKEND_URL}`;
export const LoginApi = `${backendApi}/jwt-auth/v1/token`;
export const RegisterApi = `${backendApi}/invst/register_user`;
// export const LoginApi = `http://localhost/carsharing/wp-json/simple-jwt-login/v1/auth`;

export const UserApi = {
    getAll: `${backendApi}/invst/user`,
    updateNameData: `${backendApi}/invst/user/updateName`,
    updateEmailData: `${backendApi}/invst/user/updateEmail`,
    updatePhoneData: `${backendApi}/invst/user/updatePhone`,
    updateGenderData: `${backendApi}/invst/user/updateGender`,
    updateBanking: `${backendApi}/invst/user/updateBanking`,
    updatePersonalData: `${backendApi}/invst/user/updatePersonalData`,
    updatePassword: `${backendApi}/invst/user/updatePassword`,
    updateAvatar: `${backendApi}/invst/user/updateAvatar`,
    deleteAvatar: `${backendApi}/invst/user/deleteAvatar`,
    becameOwner: `${backendApi}/invst/user/becameOwner`,
    verify: `${backendApi}/invst/user/verify`,
    checkCode: `${backendApi}/invst/user/checkCode`,
    updateDriverLicense: `${backendApi}/invst/user/updateDriverLicense`,
    addWithdraw: `${backendApi}/invst/transaction/add_withdraw`,
    getTransactions: `${backendApi}/invst/transaction/get_transactions`,
    getWithdrawFunds: `${backendApi}/invst/transaction/get_withdraw_funds`,
    updateFunds: `${backendApi}/invst/transaction/update_withdraw_funds`,

};

export const NotificationsApi = {
    getAll: `${backendApi}/invst/notifications`,
    getNew: `${backendApi}/invst/notifications/new`,
    markRead: `${backendApi}/invst/notification/mark_read`,
    markAllRead: `${backendApi}/invst/notification/mark_all_read`,
};

export const RatingsApi = {
    getAll: 'https://cb.am/latest.json.php',
}

export const AttributesApi = {
    getAll: `${backendApi}/invst/get_attributes`,
}

export const ProductsApi = {
    add: `${backendApi}/invst/add_product`,
    getMy: `${backendApi}/invst/get_my_products`,
    getProductForEdit: `${backendApi}/invst/get_product_for_edit`,
    getProductForView: `${backendApi}/invst/get_product_for_view`,
    update: `${backendApi}/invst/update_product`,
    getProducts: `${backendApi}/invst/get_products`,
    getMapProducts: `${backendApi}/invst/get_map_products`,
    calculateInventoryData: `${backendApi}/invst/calculateInventoryData`,
    toggleProductFavorite: `${backendApi}/invst/toggle_product_favorite`,
    addReview: `${backendApi}/invst/add_review`,
    getFavorites: `${backendApi}/invst/get_favorites`,
}

export const OrdersApi = {
    add: `${backendApi}/invst/addOrder`,
    checkOrderStatus: `${backendApi}/invst/check_order_status`,
    getTrips: `${backendApi}/invst/get_trips`,
    getBookings: `${backendApi}/invst/get_bookings`,
    updateStage: `${backendApi}/invst/update_order_stage`,
}

export const SettingsApi = {
    get_home_featured_product: `${backendApi}/invst/settings/get_home_featured_product`,
}