
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import NotificationsContext from "../../_helper/Notification";
import notification from "../../assets/img/Notification.png";

import Dropdown from 'react-bootstrap/Dropdown';
// import notification1 from "../../assets/img/notifications/notification1.png";
// import notification2 from "../../assets/img/notifications/notification2.png";
import driver_license from '../../assets/img/notifications/driver_license.png';
import vehicle from "../../assets/img/notifications/vehicle.png";
import transaction from '../../assets/img/notifications/transaction.png';
import booking from '../../assets/img/notifications/booking.png';

const HeaderNotifications = () => {
    const { t } = useTranslation();

    const {
        getNewNotifications,
        newNotifications,
        notifications,
    } = useContext(NotificationsContext);

    useEffect(() => {
        checkNewNotifications();
        // getNewNotifications();
    }, []);

    const checkNewNotifications = async () => {
        await getNewNotifications();
        setTimeout(function () {
            checkNewNotifications();
        }, 15000);
    }

    // const [notificationsNew, setNotificationsNew] = React.useState(false);
    // const [hasNewNotifications, setHasNewNotifications] = React.useState(false);

    // useEffect(() => {
    //     if (null != newNotifications) {
    //         // let newNotifications = notifications.filter((item) => !!item?.new);
    //         setNotificationsNew(newNotifications);
    //         if (newNotifications?.length > 0) {
    //             setHasNewNotifications(true);
    //         } else {
    //             setHasNewNotifications(false);
    //         }
    //     }
    // }, [newNotifications]);



    const icons = {
        'driver_license': driver_license,
        'vehicle': vehicle,
        'transaction': transaction,
        'booking': booking,
    };


    // const allNotifications = [
    //     {
    //         "id": 1,
    //         "message_title": "Discount available",
    //         "message_body": "We recommend a 15% discount for Tesla cars in June",
    //         "created": "2023-04-28 10:00:00",
    //         "image": notification1,
    //         "new": true,
    //         "type": 'general',
    //     },
    //     {
    //         "id": 2,
    //         "message_title": "New message from John",
    //         "message_body": "New message from John",
    //         "created": "2023-04-26 10:00:00",
    //         "image": notification1,
    //         "new": false,
    //         "type": 'general',
    //     },
    //     {
    //         "id": 3,
    //         "message_title": "New message from John",
    //         "message_body": "New message from John",
    //         "created": "2023-04-27 10:24:00",
    //         "image": notification2,
    //         "new": false,
    //         "type": 'recommendations',
    //     }
    // ];

    // const [notificationsForShow, setNotificationsForShow] = React.useState([]);
    // const [activeTab, setActiveTab] = React.useState('general');

    // React.useEffect(() => {
    //     setTab('general');
    // }, []);

    // const setTab = (tab) => {
    //     setActiveTab(tab);
    //     let notificationsToShow = allNotifications.filter(item => item.type == tab);
    //     setNotificationsForShow(notificationsToShow);
    // }

    const getCreatedDate = (created) => {
        const date = new Date();
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let currentDate = `${day}-${month}-${year}`;

        let created_date = new Date(created);
        let options = { month: "long", day: "numeric" };
        let time_str = created_date.toLocaleDateString("en-US", options);
        let itemDay = created_date.getDate();
        let itemMonth = created_date.getMonth() + 1;
        let itemYear = created_date.getFullYear();
        let itemDate = `${itemDay}-${itemMonth}-${itemYear}`;
        if (currentDate == itemDate) {
            itemDate = "Today";
        } else {
            itemDate = time_str;
        }
        return <li className="nots-date" key={itemDate}><h5>{itemDate}</h5></li>
    }

    const getCreatedTime = (created) => {
        let created_date = new Date(created);
        // This arrangement can be altered based on how we want the date's format to appear.

        // calculate the time difference in milliseconds
        let time_diff_ms = Date.now() - created_date.getTime();
        let time_str = '';
        if (time_diff_ms < 60000) {  // less than 1 minute
            // calculate the time difference in seconds
            let time_diff_sec = Math.floor(time_diff_ms / 1000);
            time_str = time_diff_sec + " seconds ago";
        } else if (time_diff_ms < 3600000) {  // less than 1 hour
            // calculate the time difference in minutes
            let time_diff_min = Math.floor(time_diff_ms / (1000 * 60));
            time_str = time_diff_min + " minutes ago";
        } else {
            // format the date as "Month Day"

            time_str = created_date.getHours() + ":" + (created_date.getMinutes() < 10 ? '0' : '') + created_date.getMinutes();
        }
        return time_str;
    }

    const [token, setToken] = React.useState(JSON.parse(localStorage.getItem("token")));

    return <>
        {!!token && <Dropdown>
            <Dropdown.Toggle className={`notification border-0 ${newNotifications?.length > 0 ? 'hasNotification' : ''}`} variant="" id="dropdown-basic" title="">
                <img src={notification} alt="" />
                {newNotifications?.length > 0
                    ? <div style={{
                        position: 'absolute',
                        top: '6px',
                        right: '6px',
                        width: '10px',
                        height: '10px',
                        border: '1px solid #fff',
                        borderRadius: '50%',
                        backgroundColor: '#EC4E5F',
                        backgroundImage: 'none !important',
                    }}></div>
                    : ''
                }
            </Dropdown.Toggle>

            <Dropdown.Menu className="notificationsDropdownMenu">
                <div className="nots-header mb-2">
                    <h4 className="notification-title">{t("HEADER.notifications")}</h4>
                    {/* <div className="tabs d-flex">
                        <div className={`tab-item d-flex ${'general' == activeTab ? 'active' : ''}`} onClick={() => { setTab('general') }} data-tab="general">
                            <h5 className="tab-title">{t("HEADER.general")}</h5>
                            <span className="tab-notifications-count"> </span>
                        </div>
                        <div className={`tab-item d-flex ${'recommendations' == activeTab ? 'active' : ''}`} onClick={() => { setTab('recommendations') }} data-tab="recommendations">
                            <h5 className="tab-title">{t("HEADER.recommendations")}</h5>
                            <span className="tab-notifications-count"></span>
                        </div>
                    </div> */}
                </div>
                {newNotifications?.length > 0
                    ? <>
                        {newNotifications.map((item, key) => {
                            return <div key={key}>
                                {key > 0
                                    ? <>
                                        {item.created_date != newNotifications[key - 1].created_date ? getCreatedDate(item.created) : ''}
                                    </>
                                    : getCreatedDate(item.created)
                                }
                                <li className={`${item.new ? 'new' : ''}`} data-id={item.id} data-key="general">
                                    <span className="dropdown-item d-flex align-items-center">
                                        {/* <img src={item.image} alt="" /> */}
                                        {!!icons[item.type] && <img src={icons[item.type]} alt="" style={{ maxWidth: '60px' }} />}
                                        <div className="w-100">
                                            <div className="d-flex justify-content-between">
                                                <h6 className="message-title">{item.title}</h6>
                                                <p className="time">{getCreatedTime(item.created)}</p>
                                            </div>
                                            <p className="message-body">{item.description}</p>
                                        </div>
                                    </span>
                                </li>
                            </div>
                        })}
                        <div className="d-flex justify-content-center py-3">
                            <Link to="/notifications" className={`btn btn-outline-warning btn-sm text-dark`}>{t('NOTIFICATIONS.all_notifications')}</Link>
                        </div>
                    </>
                    : <li className="empty-element ' + key + '">
                        <span className="dropdown-item d-flex align-items-center" >
                            <h6 className="message-title">{t("HEADER.no_new_notifications")}</h6>
                        </span>
                    </li>
                }
            </Dropdown.Menu>
        </Dropdown>
        }
    </>
}

export default HeaderNotifications