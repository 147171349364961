import React from "react";
import { Link } from 'react-router-dom';
import logo from "../../assets/img/Logo.png";
import flagUKPng from "../../assets/img/United-kingdom_flag_icon_round 1.png";
import loginSvg from "../../assets/img/iconoir_user-bag.svg";
import { useTranslation } from "react-i18next";

import socialPinterestPng from "../../assets/img/social/akar-icons_pinterest-fill.png";
import socialTwitterPng from "../../assets/img/social/akar-icons_twitter-fill.png";
import socialFacebookPng from "../../assets/img/social/ant-design_facebook-filled.png";
import socialInstagramPng from "../../assets/img/social/entypo-social_instagram.png";
import socialfeedPng from "../../assets/img/social/fa_feed.png";
import HeaderAdminChat from "./HeaderAdminChat";
import HeaderNavigationMenuOffcanvas from "./HeaderNavigationMenuOffcanvas";

const HeaderHome = () => {
    const { t } = useTranslation();
    const [showMenu, setShowMenu] = React.useState(false);
    const [token, setToken] = React.useState(JSON.parse(localStorage.getItem("token")));
    return <>
        {/* <nav className="navbar navbar-collapse navbar-light">
            <button className="navbar-toggler" type="button" onClick={() => { setShowMenu(!showMenu) }}>
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className={`offcanvas offcanvas-end ${showMenu ? 'show' : ''}`} tabIndex="-1" id="offcanvasRight"
                aria-labelledby="offcanvasRightLabel">
                <div className="offcanvas-header">
                    <button type="button" className="btn-close text-reset" onClick={() => { setShowMenu(false) }}
                        aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link className="nav-link" to="index.html">Homepage</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="vehicle-list.html">Explore Vehicles</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="about-1.html">About Us</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="#">FAQ</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="terms-of-service.html">Terms</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="privacy-policy.html">Privacy Policy</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="contact.html">Contact</Link>
                        </li>
                    </ul>
                </div>
                <div className="offcanvas-footer">
                    <div className="d-flex social">
                        <Link to="#">ant-design_facebook-filled.png</Link>
                        <Link to="#">akar-icons_twitter-fill.png</Link>
                        <Link to="#">entypo-social_instagram.png</Link>
                        <Link to="#">akar-icons_pinterest-fill.png</Link>
                        <Link to="#">fa_feed.png</Link>
                    </div>
                    <p className="copyright">Copyright &copy; 2022 All right reserved.</p>
                </div>
            </div>
            {showMenu ? <div className="offcanvas-backdrop fade show" onClick={() => { setShowMenu(false) }}></div> : ''}

        </nav> */}

        <header>
            <div className="container">
                <div className="row justify-content-between home-header">
                    <div className="left col-md-6 d-flex">
                        <nav className="position-relative d-flex flex-grow-1 align-items-center">
                            <Link to="/" className="logo"><img src={logo} alt="utrip-logo" /></Link>
                            <ul className="homapege-navbar-1 d-flex">
                                <li className="nav-item active"><Link to="/">{t('MENU.homepage')}</Link></li>
                                <li className="nav-item"><Link to="/vehicle-list">{t('MENU.explore_vehicles')}</Link></li>
                                <li className="nav-item"><Link to="/contact">{t('MENU.contact')}</Link></li>
                            </ul>
                        </nav>
                    </div>
                    <div className="right col-md-6 d-flex justify-content-end align-items-center">
                        <HeaderAdminChat />
                        <div className="login d-flex align-items-center">
                            {!token
                                ? <Link to="/login/home" className="d-flex align-items-center">
                                    <img src={loginSvg} alt="" className="icon" />
                                    <span>{t('MENU.login_or_register')}</span>
                                </Link>
                                : <Link to="/personal-info" className="d-flex align-items-center">
                                    <img src={loginSvg} alt="" className="icon" />
                                    <span>{t('MENU.my_profile')}</span>
                                </Link>
                            }

                        </div>
                        <div className="lang-swithcer dropdown">
                            <div className="dropdown-toggle d-flex align-items-center" id="dropdownMenuButton1"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                <img src={flagUKPng} alt="" className="flag" />
                            </div>
                            <ul className="dropdown-menu dropdown-menu-right w-100" aria-labelledby="dropdownMenuButton1">
                                <li>
                                    <Link to="http://example.com/en/" className="dropdown-item">
                                        <img src={flagUKPng} alt="" className="flag" />
                                    </Link>
                                </li>
                                <li>
                                    <Link to="http://example.com/en/" className="dropdown-item">
                                        <img src={flagUKPng} alt="" className="flag" />
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <HeaderNavigationMenuOffcanvas />
                    </div>
                </div>

            </div>
        </header>
    </>
}

export default HeaderHome