
import React from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import menuBurger from "../../assets/img/heroicons-outline_menu.png";
import socialPinterestPng from "../../assets/img/social/akar-icons_pinterest-fill.png";
import socialTwitterPng from "../../assets/img/social/akar-icons_twitter-fill.png";
import socialFacebookPng from "../../assets/img/social/ant-design_facebook-filled.png";
import socialInstagramPng from "../../assets/img/social/entypo-social_instagram.png";
import socialfeedPng from "../../assets/img/social/fa_feed.png";

const HeaderNavigationMenuOffcanvas = () => {
    const [showMenu, setShowMenu] = React.useState(false);
    const { t } = useTranslation();
    return <>
        <nav className="navbar navbar-collapse navbar-light">
            <button className="navbar-toggler" type="button" onClick={() => { setShowMenu(!showMenu) }}>
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className={`offcanvas offcanvas-end ${showMenu ? 'show' : ''}`} tabIndex="-1" id="offcanvasRight"
                aria-labelledby="offcanvasRightLabel">
                <div className="offcanvas-header">
                    <button type="button" className="btn-close text-reset" onClick={() => { setShowMenu(false) }}
                        aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link className="nav-link" to="/">{t('MENU.homepage')}</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/vehicle-list">{t('MENU.explore_vehicles')}</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/about">{t('MENU.about_us')}</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/faq">{t('MENU.faq')}</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/terms-of-service">{t('MENU.terms')}</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/privacy-policy">{t('MENU.privacy_policy')}</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/contact">{t('MENU.contact')}</Link>
                        </li>
                    </ul>
                </div>
                <div className="offcanvas-footer">
                    <div className="d-flex social">
                        <Link to="#"><img src={socialFacebookPng} alt="" /></Link>
                        <Link to="#"><img src={socialTwitterPng} alt="" /></Link>
                        <Link to="#"><img src={socialInstagramPng} alt="" /></Link>
                        <Link to="#"><img src={socialPinterestPng} alt="" /></Link>
                        <Link to="#"><img src={socialfeedPng} alt="" /></Link>
                    </div>
                    <p className="copyright">{t('MENU.copyright')}</p>
                </div>
            </div>
            {showMenu ? <div className="offcanvas-backdrop fade show" onClick={() => { setShowMenu(false) }}></div> : ''}
        </nav>
    </>
}

export default HeaderNavigationMenuOffcanvas