
import React, { useEffect, useRef, useState, useContext } from "react";

import message from "../../assets/img/Message.png"

import ChatContext from "../../_helper/Chat";

const HeaderMessages = () => {

    const {
        openChat,
        setOtherUser,
    } = useContext(ChatContext);


    const handleShowMessagesModal = () => {
        setOtherUser(null);
        openChat();
    };
    const [token, setToken] = React.useState(JSON.parse(localStorage.getItem("token")));

    return <>
        {!!token && <>
            <div className="dropdown">
                <button className="message p-0 border-0 dropdown-toggle btn" onClick={() => handleShowMessagesModal()}>
                    <img src={message} alt="" />
                </button>
            </div>
        </>
        }

    </>
}

export default HeaderMessages