import React, { Fragment } from "react";
import "../../assets/css/finances.css";
import "../../assets/css/profile.css";
import Footer from "../../Layout/Footer/Footer";
import Header from "../../Layout/Header/Header";
import "./../../assets/css/custom.css";
import "./../../assets/css/style.css";
import ProfileSidebar from "../../Components/Profile/ProfileSidebar";
import { Outlet } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

const ProfileLayout = () => {

    return (
        <Fragment>
            <Header />
            <main className="p-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <ProfileSidebar active={'personal-info'} />
                        </div>
                        <div className="col-md-9">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </Fragment >
    );
}

export default ProfileLayout;
