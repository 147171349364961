
import React, { useContext, useEffect, useRef, useState } from "react";
import Talk from 'talkjs';
import UserContext from "../../_helper/User";
import ChatContext from "../../_helper/Chat";

const ChatPopup = () => {

  const {
    id,
    imageUrl,
    firstName,
    lastName,
    email,
    guestUser,
    getCurrentUserData,
  } = useContext(UserContext);

  const {
    show,
    otherUser,
    closeChat,
  } = useContext(ChatContext);


  const inboxEl = useRef();

  const [talkLoaded, markTalkLoaded] = useState(false);

  const [token, setToken] = React.useState(JSON.parse(localStorage.getItem("token")));
  useEffect(() => {
    if (!!token && !id) {
      getCurrentUserData();
    }
  }, [token])

  useEffect(() => {
    if (show) {
      Talk.ready.then(() => markTalkLoaded(true));
      let currentUser = guestUser;
      if (!!id) {
        currentUser = {
          id: id,
          firstName: firstName,
          lastName: lastName,
          email: email,
          imageUrl: imageUrl,
        }
      }

      if (talkLoaded && !!currentUser.id) {
        const me = new Talk.User({
          id: currentUser.id,
          name: `${currentUser.firstName} ${currentUser.lastName}`,
          email: currentUser.email,
          photoUrl: currentUser.imageUrl,
          role: 'default',
        });

        const session = new Talk.Session({
          appId: process.env.REACT_APP_TALKJS_APP_ID,
          me: me,
        });

        if (!!otherUser) {
          let other = new Talk.User({
            id: otherUser.id,
            name: `${otherUser.firstName} ${otherUser.lastName}`,
            email: otherUser.email,
            photoUrl: otherUser.imageUrl,
            role: 'default',
          });

          const conversationId = Talk.oneOnOneId(me, other);
          const conversation = session.getOrCreateConversation(conversationId);
          conversation.setParticipant(me);
          conversation.setParticipant(other);

          const inbox = session.createInbox({ selected: conversation });
          inbox.mount(inboxEl.current);
        } else {
          const inbox = session.createInbox();
          inbox.mount(inboxEl.current);
        }

        return () => session.destroy();
      }
    }
  }, [talkLoaded, otherUser, show]);

  return <>
    {show &&
      <div className="chat-popup">
        {talkLoaded && <button type="button" className="close btn" onClick={closeChat}></button>}
        <div ref={inboxEl} className="w-100 h-100"></div>
      </div>
    }
  </>
}

export default ChatPopup