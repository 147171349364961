import React, { Fragment, useContext, useState } from 'react';
import { toast } from "react-toastify";
import { Card, CardBody, Form, FormGroup, Input, Label } from 'reactstrap';
import { Btn, Spinner } from '../../AbstractElements';
import { Save } from '../../Constant';
import UserContext from '../../_helper/User';

const EditPassword = ({ closePasswordModal }) => {

  const { updateCurrentUserPassword } = useContext(UserContext);

  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');


  const onSubmit = async () => {
    setLoading(true);

    if (newPassword === repeatPassword) {
      setErrorMessage('');
      let result = await updateCurrentUserPassword(newPassword);
      setLoading(false);
      if (true == result) {
        toast.success("Password updated");
        closePasswordModal();
      } else {
        toast.error('Something went wrong...');
      }
    } else {
      setLoading(false);
      setErrorMessage('Entered Passwords must be the same');
    }
    // closeBankDataModal();
  }
  return (
    <Fragment>
      <Card>
        <CardBody>
          <Form>
            <FormGroup className='mb-3'>
              <Label className='form-label'>New Password</Label>
              <Input className='form-control' type='password' placeholder='New Password' value={newPassword} onChange={(e) => { setNewPassword(e.target.value) }} />
            </FormGroup>
            <FormGroup className='mb-3'>
              <Label className='form-label'>Repeat Password</Label>
              <Input className='form-control' type='password' placeholder='Repeat Password' value={repeatPassword} onChange={(e) => { setRepeatPassword(e.target.value) }} />
              {!!errorMessage ? <strong className='mt-2 text-danger'>{errorMessage}</strong> : ''}
            </FormGroup>
            <div className='form-footer d-flex align-items-center'>
              <Btn attrBtn={{ onClick: onSubmit, className: 'btn-block', color: 'primary' }}>{Save}</Btn>
              {loading ?
                <div className="loader-box" style={{ width: '40px', height: '40px' }}>
                  <Spinner attrSpinner={{ style: { width: '25px', height: '25px', borderColor: '#7366ff', borderBottomColor: 'transparent' }, className: 'loader-3' }} />
                </div>
                : ''}
            </div>
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  );
};
export default EditPassword;
