
import React, { useContext } from "react";

import ChatContext from "../../_helper/Chat";
import chatPng from "../../assets/img/header-chat.png";
// import { parse } from "date-fns";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser"
const HeaderAdminChat = () => {
    const { t } = useTranslation();

    const {
        openChat,
        setOtherUser,
    } = useContext(ChatContext);

    const handleShowAdminChat = () => {
        setOtherUser({
            id: 'utriptalkadmin',
            firstName: 'admin',
            lastName: 'support',
            email: 'admin@admin.admin',
            imageUrl: process.env.PUBLIC_URL + '/header-chat.png',
        });
        openChat();
    };

    return <>
        <button className="online-chat d-flex" style={{ border: 'unset', outline: 'unset', background: 'transparent' }} onClick={() => handleShowAdminChat()}>
            <h4>{t("HEADER.online_chat")}</h4>
            <div>
                <img src={chatPng} alt="" className="icon" />
            </div>
            <p> {parse(t("HEADER.chat_desc"))} </p>
        </button>

    </>
}

export default HeaderAdminChat