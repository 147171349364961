import React, { Fragment, useRef } from "react";
import Footer from "../Layout/Footer/Footer";
import Header from "../Layout/Header/Header";
import "../assets/css/about-1.css";
import "../assets/css/about-2.css";
import "../assets/css/custom.css";
import "../assets/css/style.css";

import jackDuellio from "../assets/img/about/jack-duellio.png";
import road from "../assets/img/about/road.png";
import { Link } from "react-router-dom";
import Seo from './../Components/seo';
import { useTranslation } from "react-i18next";
const About = () => {

    const contentRef = useRef();
    const { t } = useTranslation();
    return (
        <Fragment>
            <Seo title={'About'} schema={{}} />
            <Header />
            <main className="about-1-main p-0">
                <section className="about-1-section">
                    <div className="container h-100">
                        <div className="about-1-wrapper h-100">
                            <div className="about-1 content d-flex flex-column align-items-center justify-content-center h-100">
                                <h1 className="ab-1-content-title">{t("ABOUT.about_trip")}</h1>
                                <p className="ab-1-content-text">{t("ABOUT.description")}</p>
                                <button onClick={() => {contentRef.current.scrollIntoView()}} className="ab-1-content-button">{t("ABOUT.learn_more")}</button>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <main ref={contentRef}>
                <section className="about-2-section1">
                    <div className="container">
                        <div className="about-2-section1-content">
                            <h1>{t("ABOUT.find_your_drive")}</h1>
                            <p>
                                Utrip is the world’s largest car sharing marketplace where you can book any car you want,
                                wherever you want it, from a vibrant community of trusted hosts across the US, Canada, and
                                the UK. Whether you're flying in from afar or looking for a car down the street, searching
                                for a rugged truck or something smooth and swanky, guests can take the wheel of the perfect
                                car for any occasion, while hosts can take the wheel of their futures by building an
                                accessible, flexible, and scalable car sharing business from the ground up.
                            </p>
                        </div>
                    </div>
                </section>
                <section className="about-2-section2">
                    <div className="container">
                        <div className="about-2-section2-content">
                            <div className="ab-2-content-image-sec2">
                                <img src={jackDuellio} alt="" />
                            </div>

                            <div className="ab-2-content-text-sec2">
                                <h3 className="sec-2-title">{t("ABOUT.company_mission")}</h3>

                                <p>{t("ABOUT.mission_description")}</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="about-2-section3">
                    <div className="container">
                        <div className="about-2-section3-content">
                            <h2>{t("ABOUT.utrip_by_the_numbers")}</h2>

                            <div className="about-2-sec3-row-1 d-flex">
                                <div className="row1-col1 row-col">
                                    <h2>{t("ABOUT.utrip_by_the_numbers_items.0.title")}</h2>

                                    <p>{t("ABOUT.utrip_by_the_numbers_items.0.description")}</p>
                                </div>

                                <div className="row2-col2 row-col">
                                    <h2>{t("ABOUT.utrip_by_the_numbers_items.1.title")}</h2>

                                    <p>{t("ABOUT.utrip_by_the_numbers_items.1.description")}</p>
                                </div>

                                <div className="row3-col3 row-col">
                                    <h2>{t("ABOUT.utrip_by_the_numbers_items.2.title")}</h2>

                                    <p>{t("ABOUT.utrip_by_the_numbers_items.2.description")}</p>
                                </div>
                            </div>

                            <div className="about-2-sec3-row-2">
                                <div className=" row-col">
                                    <h2>{t("ABOUT.utrip_by_the_numbers_items.3.title")}</h2>

                                    <p>{t("ABOUT.utrip_by_the_numbers_items.3.description")}</p>
                                </div>

                                <div className="row-2-col-2 row-col max-width">
                                    <h2>{t("ABOUT.utrip_by_the_numbers_items.4.title")}</h2>

                                    <p>{t("ABOUT.utrip_by_the_numbers_items.4.description")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="about-2-section4">
                    <div className="container">
                        <div className="about-2-section4-content">
                            <div className="ab-2-sec4-left d-flex justify-content-between flex-column">
                                <div>
                                    <h2>{t("ABOUT.sustainable")}</h2>
                                    <p>
                                        Utrip offsets 100% of our estimated global carbon emissions by investing in greenhouse
                                        gas-reducing projects, so you can rest easy building your businesses and satisfying your
                                        wanderlust knowing that our carbon footprint is accounted for.
                                    </p>
                                </div>
                                <div>
                                    <Link to="#" className="ab-dark-button">{t("ABOUT.learn_more_about_the_program")}</Link>
                                </div>
                            </div>

                            <div className="ab-2-sec4-right">
                                <img src={road} alt="" />
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />

        </Fragment>
    );
}

export default About;
