
import React, { useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useNavigate } from "react-router-dom";

import play from "../../assets/img/Play.png"
import profileHeart from "../../assets/img/profile/Heart.png";
import profileLogout from "../../assets/img/profile/Logout.png";
import profileLocale from "../../assets/img/profile/globe.png";
import profileHelp from "../../assets/img/profile/help-circle.png";
import profileKey from "../../assets/img/profile/key-24.png";
import profile from "../../assets/img/profile/profile.png";
import UserContext from "../../_helper/User";
import { useTranslation } from "react-i18next";

const HeaderProfileMenu = () => {
    const { t } = useTranslation();

    const {
        logout,
        role,
    } = React.useContext(UserContext);


    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const handleCloseLogoutModal = () => setShowLogoutModal(false);
    const handleShowLogoutModal = () => setShowLogoutModal(true);
    const history = useNavigate();
    const handleLogout = () => {
        logout();
        history(`${process.env.PUBLIC_URL}/`);
    };

    const [showLanguageCurrencyModal, setShowLanguageCurrencyModal] = useState(false);
    const handleCloseLanguageCurrencyModal = () => setShowLanguageCurrencyModal(false);
    const handleShowLanguageCurrencyModal = () => setShowLanguageCurrencyModal(true);

    const [token, setToken] = useState(JSON.parse(localStorage.getItem("token")));

    return <>
        {!token
            ? <div className="dropdown">
                <Link to="/favorites" className="profile p-0 border-0 dropdown-toggle btn">
                    <img src={play} alt="" />
                </Link>
            </div>
            : <Dropdown>
                <Dropdown.Toggle className="profile p-0 border-0 " variant="" id="dropdown-basic" title="">
                    <img src={play} alt="" />
                </Dropdown.Toggle>

                <Dropdown.Menu className="profileDropdownMenu">
                    <li>
                        <Link to="/favorites" className="profile-dropdown-item">
                            <img src={profileHeart} alt="" /><span>{t("HEADER.profile.favorites")}</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/profile" className="profile-dropdown-item">
                            <img src={profile} alt="" /><span>{t("HEADER.profile.profile")}</span>
                        </Link>
                    </li>
                    {
                        (!!role && 'owner' == role) && <li>
                            <Link to="/my-vehicles" className="profile-dropdown-item">
                                <img src={profileKey} alt="" /><span>{t("HEADER.profile.list_your_car")}</span>
                            </Link>
                        </li>
                    }
                    <li>
                        <span className="profile-dropdown-item" onClick={handleShowLanguageCurrencyModal} role="button">
                            <img src={profileLocale} alt="" /><span>{t("HEADER.profile.language_currency.title")}</span>
                        </span>
                    </li>
                    <li>
                        <Link to="/help-center" className="profile-dropdown-item">
                            <img src={profileHelp} alt="" /><span>{t("HEADER.profile.support")}</span>
                        </Link>
                    </li>
                    <div className="hor-divider"></div>
                    <li>
                        <span className="profile-dropdown-item" onClick={handleShowLogoutModal}>
                            <img src={profileLogout} alt="" /><span>{t("HEADER.profile.log_out.title")}</span>
                        </span>
                    </li>
                </Dropdown.Menu>
            </Dropdown>
        }



        <div className="modal fade" id="langauage_currency_modal" tabIndex="-1"
            aria-labelledby="langauage_currency_modalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header flex-column">
                        <button type="button" className="btn-close" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                        <h5 className="modal-title">
                        {t("HEADER.profile.language_currency.modal_title")}
                        </h5>
                    </div>
                    <div className="modal-body">
                        <ul className="nav nav-tabs lang-and-currency-tabs-wrapper" role="tablist">
                            <li className="nav-item modal-tab-item" role="presentation">
                                <button className="nav-link active" id="language-and-region-tab"
                                    data-bs-toggle="tab" data-bs-target="#language-and-region"
                                    type="button" role="tab" aria-controls="language-and-region"
                                    aria-selected="true">{t("HEADER.profile.language_currency.modal_title")}</button>
                            </li>
                            <li className="nav-item modal-tab-item" role="presentation">
                                <button className="nav-link" id="currency" data-bs-toggle="tab"
                                    data-bs-target="#currency-tab" type="button" role="tab"
                                    aria-controls="currency"
                                    aria-selected="false">{t("HEADER.profile.language_currency.modal_title")}</button>
                            </li>
                        </ul>
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="language-and-region"
                                role="tabpanel" aria-labelledby="language-and-region-tab">
                                <div className="row langs-and-regions-wrapper">
                                    <div className="col-md-3">
                                        <Link to="#" className="language-and-region active">
                                            <h4 className="language">English</h4>
                                            <h4 className="region">United States</h4>
                                        </Link>
                                    </div>
                                    <div className="col-md-3">
                                        <Link to="#" className="language-and-region">
                                            <h4 className="language">Bahasa Indonesia</h4>
                                            <h4 className="region">Indonesia</h4>
                                        </Link>
                                    </div>
                                    <div className="col-md-3">
                                        <Link to="#" className="language-and-region">
                                            <h4 className="language">Bosanski</h4>
                                            <h4 className="region">Bosna i Hercegovina</h4>
                                        </Link>
                                    </div>
                                    <div className="col-md-3">
                                        <Link to="#" className="language-and-region">
                                            <h4 className="language">Catala</h4>
                                            <h4 className="region">Espanya</h4>
                                        </Link>
                                    </div>
                                    <div className="col-md-3">
                                        <Link to="#" className="language-and-region">
                                            <h4 className="language">Dansk</h4>
                                            <h4 className="region">Danmark</h4>
                                        </Link>
                                    </div>
                                    <div className="col-md-3">
                                        <Link to="#" className="language-and-region">
                                            <h4 className="language">Deutsch</h4>
                                            <h4 className="region">Deutschland</h4>
                                        </Link>
                                    </div>
                                    <div className="col-md-3">
                                        <Link to="#" className="language-and-region">
                                            <h4 className="language">Eesti</h4>
                                            <h4 className="region">Eesti</h4>
                                        </Link>
                                    </div>
                                    <div className="col-md-3">
                                        <Link to="#" className="language-and-region">
                                            <h4 className="language">English</h4>
                                            <h4 className="region">Australia</h4>
                                        </Link>
                                    </div>
                                    <div className="col-md-3">
                                        <Link to="#" className="language-and-region">
                                            <h4 className="language">English</h4>
                                            <h4 className="region">United States</h4>
                                        </Link>
                                    </div>
                                    <div className="col-md-3">
                                        <Link to="#" className="language-and-region">
                                            <h4 className="language">Bahasa Indonesia</h4>
                                            <h4 className="region">Indonesia</h4>
                                        </Link>
                                    </div>
                                    <div className="col-md-3">
                                        <Link to="#" className="language-and-region">
                                            <h4 className="language">Bosanski</h4>
                                            <h4 className="region">Bosna i Hercegovina</h4>
                                        </Link>
                                    </div>
                                    <div className="col-md-3">
                                        <Link to="#" className="language-and-region">
                                            <h4 className="language">Catala</h4>
                                            <h4 className="region">Espanya</h4>
                                        </Link>
                                    </div>
                                    <div className="col-md-3">
                                        <Link to="#" className="language-and-region">
                                            <h4 className="language">Dansk</h4>
                                            <h4 className="region">Danmark</h4>
                                        </Link>
                                    </div>
                                    <div className="col-md-3">
                                        <Link to="#" className="language-and-region">
                                            <h4 className="language">Deutsch</h4>
                                            <h4 className="region">Deutschland</h4>
                                        </Link>
                                    </div>
                                    <div className="col-md-3">
                                        <Link to="#" className="language-and-region">
                                            <h4 className="language">Eesti</h4>
                                            <h4 className="region">Eesti</h4>
                                        </Link>
                                    </div>
                                    <div className="col-md-3">
                                        <Link to="#" className="language-and-region">
                                            <h4 className="language">English</h4>
                                            <h4 className="region">Australia</h4>
                                        </Link>
                                    </div>
                                    <div className="col-md-3">
                                        <Link to="#" className="language-and-region">
                                            <h4 className="language">Dansk</h4>
                                            <h4 className="region">Danmark</h4>
                                        </Link>
                                    </div>
                                    <div className="col-md-3">
                                        <Link to="#" className="language-and-region">
                                            <h4 className="language">Deutsch</h4>
                                            <h4 className="region">Deutschland</h4>
                                        </Link>
                                    </div>
                                    <div className="col-md-3">
                                        <Link to="#" className="language-and-region">
                                            <h4 className="language">Eesti</h4>
                                            <h4 className="region">Eesti</h4>
                                        </Link>
                                    </div>
                                    <div className="col-md-3">
                                        <Link to="#" className="language-and-region">
                                            <h4 className="language">English</h4>
                                            <h4 className="region">Australia</h4>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="currency-tab" role="tabpanel"
                                aria-labelledby="currency">{t("HEADER.profile.language_currency.currency_content")}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Modal id="langauage_currency_modal" className="" show={showLanguageCurrencyModal} onHide={handleCloseLanguageCurrencyModal} centered scrollable>
            <Modal.Header className="flex-column-reverse" closeButton>
                <h5 className="modal-title">
                    {t("HEADER.profile.language_currency.modal_title")}
                </h5>
            </Modal.Header>
            <Modal.Body>
                <Tabs
                    defaultActiveKey="language"
                    id="language-and-region"
                    className="mb-3 lang-and-currency-tabs-wrapper"
                >
                    <Tab eventKey="language" title="Language and region" tabAttrs={{ className: 'modal-tab-item' }}>
                        <div className="row langs-and-regions-wrapper">
                            <div className="col-md-3">
                                <button className="language-and-region active">
                                    <h4 className="language">English</h4>
                                    <h4 className="region">United States</h4>
                                </button>
                            </div>
                            <div className="col-md-3">
                                <button className="language-and-region">
                                    <h4 className="language">Bahasa Indonesia</h4>
                                    <h4 className="region">Indonesia</h4>
                                </button>
                            </div>
                            <div className="col-md-3">
                                <button className="language-and-region">
                                    <h4 className="language">Bosanski</h4>
                                    <h4 className="region">Bosna i Hercegovina</h4>
                                </button>
                            </div>
                            <div className="col-md-3">
                                <button className="language-and-region">
                                    <h4 className="language">Catala</h4>
                                    <h4 className="region">Espanya</h4>
                                </button>
                            </div>
                            <div className="col-md-3">
                                <button className="language-and-region">
                                    <h4 className="language">Dansk</h4>
                                    <h4 className="region">Danmark</h4>
                                </button>
                            </div>
                            <div className="col-md-3">
                                <button className="language-and-region">
                                    <h4 className="language">Deutsch</h4>
                                    <h4 className="region">Deutschland</h4>
                                </button>
                            </div>
                            <div className="col-md-3">
                                <button className="language-and-region">
                                    <h4 className="language">Eesti</h4>
                                    <h4 className="region">Eesti</h4>
                                </button>
                            </div>
                            <div className="col-md-3">
                                <button className="language-and-region">
                                    <h4 className="language">English</h4>
                                    <h4 className="region">Australia</h4>
                                </button>
                            </div>
                            <div className="col-md-3">
                                <button className="language-and-region">
                                    <h4 className="language">English</h4>
                                    <h4 className="region">United States</h4>
                                </button>
                            </div>
                            <div className="col-md-3">
                                <button className="language-and-region">
                                    <h4 className="language">Bahasa Indonesia</h4>
                                    <h4 className="region">Indonesia</h4>
                                </button>
                            </div>
                            <div className="col-md-3">
                                <button className="language-and-region">
                                    <h4 className="language">Bosanski</h4>
                                    <h4 className="region">Bosna i Hercegovina</h4>
                                </button>
                            </div>
                            <div className="col-md-3">
                                <button className="language-and-region">
                                    <h4 className="language">Catala</h4>
                                    <h4 className="region">Espanya</h4>
                                </button>
                            </div>
                            <div className="col-md-3">
                                <button className="language-and-region">
                                    <h4 className="language">Dansk</h4>
                                    <h4 className="region">Danmark</h4>
                                </button>
                            </div>
                            <div className="col-md-3">
                                <button className="language-and-region">
                                    <h4 className="language">Deutsch</h4>
                                    <h4 className="region">Deutschland</h4>
                                </button>
                            </div>
                            <div className="col-md-3">
                                <button className="language-and-region">
                                    <h4 className="language">Eesti</h4>
                                    <h4 className="region">Eesti</h4>
                                </button>
                            </div>
                            <div className="col-md-3">
                                <button className="language-and-region">
                                    <h4 className="language">English</h4>
                                    <h4 className="region">Australia</h4>
                                </button>
                            </div>
                            <div className="col-md-3">
                                <button className="language-and-region">
                                    <h4 className="language">Dansk</h4>
                                    <h4 className="region">Danmark</h4>
                                </button>
                            </div>
                            <div className="col-md-3">
                                <button className="language-and-region">
                                    <h4 className="language">Deutsch</h4>
                                    <h4 className="region">Deutschland</h4>
                                </button>
                            </div>
                            <div className="col-md-3">
                                <button className="language-and-region">
                                    <h4 className="language">Eesti</h4>
                                    <h4 className="region">Eesti</h4>
                                </button>
                            </div>
                            <div className="col-md-3">
                                <button className="language-and-region">
                                    <h4 className="language">English</h4>
                                    <h4 className="region">Australia</h4>
                                </button>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="currency" title="Currency" tabAttrs={{ className: 'modal-tab-item' }}>
                        Currency Content
                    </Tab>
                </Tabs>
            </Modal.Body>
        </Modal>

        <Modal id="logout_modal" className="" show={showLogoutModal} onHide={handleCloseLogoutModal} centered scrollable>
            <Modal.Header className="flex-column-reverse" closeButton>
                {/* <button type="button" className="btn-close" onClick={handleCloseLogoutModal}></button> */}
                <h5 className="modal-title">{t("HEADER.profile.log_out.modal_title")}</h5>
            </Modal.Header>
            <Modal.Body>
                <p>{t("HEADER.profile.log_out.modal_alert")}</p>
            </Modal.Body>
            <Modal.Footer>
                {/* <button type="button" className="close-btn" onClick={handleCloseLogoutModal}>Cancel</button> */}
                <button type="button" className="logout-btn" onClick={handleLogout}>{t("HEADER.profile.log_out.confirm_btn")}</button>
            </Modal.Footer>
        </Modal>
    </>
}

export default HeaderProfileMenu