import axios from 'axios';
import React, { Fragment, useEffect, useState } from "react";
import { Form, FormGroup, Input } from "reactstrap";
import InputGroup from 'react-bootstrap/InputGroup';
import { ForgotPassword, SignIn } from "../Constant";

import { Link, useNavigate, useParams } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import { RegisterApi } from "../api";
import Seo from './../Components/seo';

import HeaderHome from "../Layout/Header/HeaderHome";
import Header2 from "../Layout/Header/Header2";
import HomeSlider from './../Components/Home/HomeSlider';

// import "../assets/css/home-map.css"
import "../assets/css/custom.css";
import "../assets/css/home.css";
import "../assets/css/home-mobile.css";
import "../assets/css/signin.css";
import "../assets/css/style.css";
import { useTranslation } from "react-i18next";

const Signup = ({ selected }) => {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [passwordShow, setPasswordShow] = useState(false);


  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [termsAgreement, setTermsAgreement] = useState(false);
  const [errors, setErrors] = useState([]);
  const [role, setRole] = useState('renter');
  const history = useNavigate();


  const [token, setToken] = useState(JSON.parse(localStorage.getItem("token")));

  const { redirect } = useParams();

  useEffect(() => {
    if (!!token) {
      if (!!redirect) {
        history(`${process.env.PUBLIC_URL}/login/${redirect}`);
      } else {
        history(`${process.env.PUBLIC_URL}/login`);
      }
    }
  }, [token, redirect]);

  const registerUser = (e) => {
    e.preventDefault();
    let newErrors = [...errors];
    if (!!firstName) {
      newErrors = newErrors.filter(item => item != 'firstName');
    } else {
      newErrors.push('firstName');
    }
    if (!!lastName) {
      newErrors = newErrors.filter(item => item != 'lastName');
    } else {
      newErrors.push('lastName');
    }
    if (!!email) {
      newErrors = newErrors.filter(item => item != 'email');
    } else {
      newErrors.push('email');
    }
    if (!!password) {
      newErrors = newErrors.filter(item => item != 'password');
    } else {
      newErrors.push('password');
    }
    setErrors(newErrors);
    if (newErrors?.length == 0) {
      const requestData = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: password,
        role: role,
      };

      axios.post(`${RegisterApi}`, requestData)
        .then(res => {
          if (!!res?.data?.success) {
            toast.success(t("SIGN_UP.successfully_signed_up"), {
              onClose: (e) => {
                if (!!redirect) {
                  history(`${process.env.PUBLIC_URL}/${redirect}`);
                } else {
                  history(`${process.env.PUBLIC_URL}/login`);
                }
              }
            });
          } else {
            toast.error(t("SIGN_UP.went_wrong"));
          }
        })
        .catch(err => {
          toast.error(err?.response?.data?.message);
        })
    }
  }

  return (
    <Fragment>
      <Seo title={'Sign Up'} schema={{}} />
      <div className="d-none d-lg-block">
        <HeaderHome />
      </div>
      <div className="d-lg-none">
        <Header2 />
      </div>
      <div className="home d-flex login-page">
        <HomeSlider classes={' d-none d-lg-block'} />
        <section className="login-section flex-grow-1 p-e-container align-items-center justify-content-center d-flex ">
          <div className="login-card">
            <div className="login-main login-tab">
              <h4 className='login-title'>{t("SIGN_UP.welcome_user")}</h4>
              <p className='login-subtitle'>{t("SIGN_UP.sign_up_to_join")}</p>
              <Form className="login-form" onSubmit={(e) => registerUser(e)}>
                <div className='role-toggler mb-3'>
                  <label className={`${role == 'renter' ? 'active' : ''}`}>{t("SIGN_UP.register_as_renter")}<input type='radio' className='d-none' onClick={() => { setRole('renter') }} /></label>
                  <label className={`${role == 'owner' ? 'active' : ''}`}>{t("SIGN_UP.register_as_owner")}<input type='radio' className='d-none' onClick={() => { setRole('owner') }} /></label>
                </div>
                <FormGroup>
                  {/* <Label className="col-form-label">{EmailAddress}</Label> */}
                  <Input required invalid={errors.includes('firstName')} className="form-control login-input" placeholder={t("SIGN_UP.first_name")} type="text" onChange={(e) => setFirstName(e.target.value)} value={firstName} />
                </FormGroup>
                <FormGroup>
                  {/* <Label className="col-form-label">{EmailAddress}</Label> */}
                  <Input required invalid={errors.includes('lastName')} className="form-control login-input" placeholder={t("SIGN_UP.last_name")} type="text" onChange={(e) => setLastName(e.target.value)} value={lastName} />
                </FormGroup>
                <FormGroup>
                  {/* <Label className="col-form-label">{EmailAddress}</Label> */}
                  <Input required invalid={errors.includes('email')} className="form-control login-input" placeholder={t("SIGN_UP.email")} type="email" onChange={(e) => setEmail(e.target.value)} value={email} />
                </FormGroup>
                <FormGroup className="position-relative">
                  <InputGroup className="mb-3">
                    <Input required invalid={errors.includes('password')} className="form-control login-input m-0" placeholder={t("SIGN_UP.password")} type={`${passwordShow ? 'text' : 'password'}`} onChange={(e) => setPassword(e.target.value)} value={password} />
                    <InputGroup.Text style={{ minWidth: '65px' }} className={`ms-0 px-2 user-select-none cursor-pointer justify-content-center login-input m-0 ${errors.includes('password') ? 'border-danger' : ''}`} onClick={() => { setPasswordShow(!passwordShow) }}>{passwordShow ? t("SIGN_UP.hide") : t("SIGN_UP.show")}</InputGroup.Text>
                  </InputGroup>
                </FormGroup>
                <div className="position-relative form-group my-3">
                  <div className="checkbox d-flex align-items-center cursor-pointer user-select-none" style={{ gap: '8px' }} onClick={(e) => { setTermsAgreement(!termsAgreement) }}>
                    <input type="checkbox" name='remember' className='d-none' checked={termsAgreement} readOnly />
                    <div className={`custom-check ${termsAgreement ? 'active' : ''}`}></div>
                    {'I agree to the '}
                    <a href='/terms-of-service' target="_blank">{t("SIGN_UP.terms_of_service")}</a>
                  </div>
                </div>
                <button type='submit' className="btn login-button d-block w-100 mt-2" disabled={!termsAgreement}>{t("SIGN_UP.sign_up")}</button>
                <div className='signin-signup-form-footer'>
                {t("SIGN_UP.have_an_account")} <Link to="/login">{t("SIGN_UP.sign_in")}</Link>
                </div>
              </Form>
            </div>
          </div>
        </section>
      </div>
      <ToastContainer theme="colored" />
    </Fragment>
  );
};

export default Signup;
