import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = ({ children, redirect }) => {

    const [token, setToken] = useState(JSON.parse(localStorage.getItem("token")));
    if (!token) {
        let to = `${process.env.PUBLIC_URL}/login`;
        if (!!redirect) {
            to += `${redirect}`;
        }
        return <Navigate exact to={to} />;
    }

    return children;
};
export default ProtectedRoute;