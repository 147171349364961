import axios from 'axios';
import React, { Fragment, useEffect, useState } from "react";
import { Form, FormGroup, Input } from "reactstrap";
import { ForgotPassword, SignIn } from "../Constant";

import { Link, useNavigate, useParams } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import { LoginApi } from "../api";
import Seo from './../Components/seo';

import HeaderHome from "../Layout/Header/HeaderHome";
import Header2 from "../Layout/Header/Header2";
import HomeSlider from './../Components/Home/HomeSlider';

// import "../assets/css/home-map.css"
import "../assets/css/custom.css";
import "../assets/css/home.css";
import "../assets/css/home-mobile.css";
import "../assets/css/signin.css";
import "../assets/css/style.css";
import { useTranslation } from "react-i18next";

const Signin = ({ selected }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("admin");
  const [password, setPassword] = useState("admin");
  const [rememberMe, setRememberMe] = useState(false);
  const history = useNavigate();

  const [token, setToken] = useState(JSON.parse(localStorage.getItem("token")));

  const { redirect } = useParams();

  useEffect(() => {
    if (!!token) {
      if (!!redirect) {
        history(`${process.env.PUBLIC_URL}/${redirect}`);
      } else {
        history(`${process.env.PUBLIC_URL}/profile`);
      }
    }
  }, [token, redirect]);

  const loginAuth = async (e) => {
    e.preventDefault();

    const loginData = {
      username: email,
      password: password,
    };

    axios.post(`${LoginApi}`, loginData)
      .then(res => {

        if (undefined === res.data.token) {
          toast.error(t("SIGN_IN.error_alert"));
          return;
        }

        const { token, user_nicename, user_email } = res.data;


        localStorage.setItem("login", JSON.stringify(true));
        if (!!redirect) {
          history(`${process.env.PUBLIC_URL}/${redirect}`);
        } else {
          history(`${process.env.PUBLIC_URL}/vehicle-list`);
        }
        toast.success(t("SIGN_IN.success_alert"));

        localStorage.setItem('token', JSON.stringify(token));
        localStorage.setItem('userName', user_nicename);


        // navigate('/dashboard');
        // setLoading(false);
      })
      .catch(err => {
        console.log(err);
        toast.error(t("SIGN_IN.error_alert"));
        // toast.error(err.response.data.message);
      })
  };


  return (
    <Fragment>
      <Seo title={'Sign In'} schema={{}} />
      <div className="d-none d-lg-block">
        <HeaderHome />
      </div>
      <div className="d-lg-none">
        <Header2 />
      </div>
      <div className="home d-flex login-page">
        <HomeSlider classes={' d-none d-lg-block'}/>
        <section className="login-section flex-grow-1 p-e-container align-items-center justify-content-center d-flex ">
          <div className="login-card">
            <div className="login-main login-tab">
              <h4 className='login-title'>{t("SIGN_IN.welcome_back")}</h4>
              <p className='login-subtitle'>{t("SIGN_IN.sign_in_to_continue")}</p>
              <Form className="login-form">
                <FormGroup>
                  {/* <Label className="col-form-label">{EmailAddress}</Label> */}
                  <Input className="form-control login-input" placeholder='Email' type="email" onChange={(e) => setEmail(e.target.value)} value={email} />
                </FormGroup>
                <FormGroup className="position-relative">
                  {/* <Label className="col-form-label">{Password}</Label> */}
                  <div className="position-relative">
                    <Input className="form-control login-input" placeholder='Password' type="password" onChange={(e) => setPassword(e.target.value)} value={password} />
                  </div>
                </FormGroup>
                <div className="position-relative form-group my-3">
                  <div className="checkbox d-flex align-items-center cursor-pointer user-select-none" style={{ gap: '8px' }} onClick={(e) => { setRememberMe(!rememberMe) }}>
                    <input type="checkbox" name='remember' className='d-none' checked={rememberMe} readOnly />
                    <div className={`custom-check ${rememberMe ? 'active' : ''}`}></div>
                    {t("SIGN_IN.remember_me")}
                  </div>
                  <a className="link d-none" href="#javascript">
                    {t("SIGN_IN.forgot_password")}
                  </a>
                </div>
                <button className="btn login-button d-block w-100 mt-2" onClick={(e) => loginAuth(e)}>{t("SIGN_IN.sign_in")}</button>
                <div className='signin-signup-form-footer'>
                  {t("SIGN_IN.don_t_have_an_account")}<Link to="/register">{t("SIGN_IN.sign_up")}</Link>
                </div>
              </Form>
            </div>
          </div>
        </section>
      </div>
      <ToastContainer theme="colored" />
    </Fragment>
  );
};

export default Signin;
