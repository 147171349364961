

import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from "react-i18next";

import document from '../../assets/img/profile/Document.png';
import lock from '../../assets/img/profile/Lock.png';
import creditCard from '../../assets/img/profile/credit-card.png';
import discovery from '../../assets/img/profile/Discovery.png';
import globe from '../../assets/img/profile/globe.png';
import notification from "../../assets/img/Notification.png";
import { useContext } from "react";
import UserContext from "../../_helper/User";

const ProfileSidebar = () => {
    const { t } = useTranslation();

    const location = useLocation();
    const [defaultActiveKey, setDefaultActiveKey] = useState(false);

    const {
        role,
    } = React.useContext(UserContext);

    React.useEffect(() => {
        switch (location.pathname) {
            case '/finances':
            case '/payments':
                setDefaultActiveKey('1');
                break;
            case '/bookings':
            case '/trips':
                setDefaultActiveKey('2');
                break;
            case '/notifications':
                setDefaultActiveKey('3');
                break;
            case 'language-and-currency':
                setDefaultActiveKey('4');
                break;

            case '/personal-info':
            case '/login-and-security':
            default:
                setDefaultActiveKey('0');
                break;
        }
    }, [])

    return (
        <div className="profile-sidebar h-100">
            {defaultActiveKey !== false ?
                <Accordion defaultActiveKey={defaultActiveKey} id="sidebar-nav" alwaysOpen>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>{t('ACCOUNT_SIDEBAR.profile')}</Accordion.Header>
                        <Accordion.Body>
                            <Link className="dropdown-item active" to="/personal-info">
                                <img src={document} alt="" />
                                <span>{t('ACCOUNT_SIDEBAR.personal_info')}</span>
                            </Link>
                            <Link className="dropdown-item" to="/login-and-security" account-page="login-and-security">
                                <img src={lock} alt="" />
                                <span>{t('ACCOUNT_SIDEBAR.login_and_security')}</span>
                            </Link>
                            <Link className="dropdown-item" to="/driver-license" account-page="driver-license">
                                <img src={document} alt="" />
                                <span>{t('ACCOUNT_SIDEBAR.driver_license')}</span>
                            </Link>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>{t('ACCOUNT_SIDEBAR.payments_and_payouts')}</Accordion.Header>
                        <Accordion.Body>
                            <Link className="dropdown-item" to="/payments" account-page="review-payments">
                                <img src={creditCard} alt="" />
                                <span>{t('ACCOUNT_SIDEBAR.rewiew_payments')}</span>
                            </Link>
                            <Link className="dropdown-item" to="/finances" account-page="review-payments">
                                <img src={creditCard} alt="" />
                                <span>{t('ACCOUNT_SIDEBAR.finances')}</span>
                            </Link>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>{t('ACCOUNT_SIDEBAR.trips')}</Accordion.Header>
                        <Accordion.Body>
                            <Link className="dropdown-item" to="/trips" account-page="all-trips">
                                <img src={discovery} alt="" />
                                <span>{t('ACCOUNT_SIDEBAR.all_trips')}</span>
                            </Link>
                            {
                                (!!role && 'owner' == role) && <Link className="dropdown-item" to="/bookings" account-page="all-bookings">
                                    <img src={discovery} alt="" />
                                    <span>{t('ACCOUNT_SIDEBAR.bookings_of_my_cars')}</span>
                                </Link>
                            }
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>{t('ACCOUNT_SIDEBAR.notifications')}</Accordion.Header>
                        <Accordion.Body id="notifications-nav">
                            <Link className="dropdown-item justify-content-start mb-2" to="/notifications" account-page="all-trips">
                                <img src={notification} alt="" />
                                <span>{t('ACCOUNT_SIDEBAR.notifications')}</span>
                            </Link>
                            <a className="notifications-item" href="#"><span>{t('ACCOUNT_SIDEBAR.system_notifications')}</span>
                                <div className="block">
                                    <input data-index="0" id="system_notifications" type="checkbox" />
                                    <label htmlFor="system_notifications"></label>
                                </div>
                            </a>
                            <a className="notifications-item" href="#">
                                <span>{t("ACCOUNT_SIDEBAR.notifications_about")}
                                </span>
                                <div className="block">
                                    <input data-index="0" id="special_notifications" type="checkbox" />
                                    <label htmlFor="special_notifications"></label>
                                </div>
                            </a>
                        </Accordion.Body>
                    </Accordion.Item>
                    {/* <Accordion.Item eventKey="4">
                        <Accordion.Header>Language & Currency</Accordion.Header>
                        <Accordion.Body>
                            <a className="dropdown-item" href="#" account-page="choose-a-language-and-currency">
                                <img src={globe} alt="" />Choose a language and currency
                            </a>
                        </Accordion.Body>
                    </Accordion.Item> */}
                </Accordion>
                : ''
            }
        </div>
    );
}

export default ProfileSidebar;
